import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:slug?",
      name: "zolturd",
      meta: {
        title: "Zolturd"
      },
      components: {
        Zolturd: () => import("./components/Zolturd.vue")
      },
      props: { Zolturd: true }
    }
  ]
});
