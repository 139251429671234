<template>
  <div id="app">
    <keep-alive>
      <router-view class="zolturd" name="Zolturd"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import { metaInfo } from "./seo/Meta";

export default {
  name: "App",
  metaInfo
};
</script>
<style>
@import "./assets/main.scss";
</style>
