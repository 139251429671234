import dateFormat from "dateformat";

export const title =
  process.env.VUE_APP_ENV !== "production"
    ? process.env.VUE_APP_TITLE + " " + dateFormat(new Date())
    : process.env.VUE_APP_TITLE;
// Set your Siteinfo Here (or in .env)
export const siteInfo = {
  title: title,
  name: "Why go to Riot Fest?",
  description: "",
  url: `https://myreason.riotfest.org`,
  twitteruser: "riotfest",
  image: "https://riotfest.org/wp-content/uploads/2019/05/your-reason.jpg",
  summary: "Let Zolturd tell you your fortune!",
  fb_app_id: "425027414945459"
};

// Add all your meta stuff here
export const metaInfo = {
  title: siteInfo.title,
  meta: [
    { httpEquiv: "Content-Type", content: "text/html; charset=utf-8" },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
    { property: "og:type", content: "website" },

    { name: "twitter:card", content: siteInfo.summary },
    { name: "twitter:creator", content: siteInfo.twitteruser },

    { property: "og:url", content: siteInfo.url },
    { name: "twitter:site", content: siteInfo.url },

    { itemprop: "name", content: siteInfo.name },
    { property: "og:site_name", content: siteInfo.name },

    { name: "twitter:description", content: siteInfo.description },
    { property: "og:description", content: siteInfo.description },
    { itemprop: "description", content: siteInfo.description },

    { property: "og:title", content: siteInfo.title },
    { name: "twitter:title", content: siteInfo.title },
    {
      name: "twitter:image:src",
      content: siteInfo.image
    },
    {
      itemprop: "image",
      content: siteInfo.image
    },
    { property: "og:image", content: siteInfo.image },
    { property: "og:image:width", content: 720 },
    { property: "og:image:height", content: 377 },

    // facebook app id
    { property: "fb:app_id", content: siteInfo.fb_app_id }
  ],
  link: [{ rel: "canonical", href: siteInfo.url }]
};

export const mutateMeta = {
  updateSingle(meta, tagname = "meta") {
    let proptype = Object.keys(meta)[0];
    let property = meta[proptype];
    let valuetype = Object.keys(meta)[1];
    let value = meta[valuetype];
    let tag;
    if (document.querySelector(`${tagname}[${proptype}="${property}"]`)) {
      tag = document.querySelector(`${tagname}[${proptype}="${property}"]`);
    } else {
      tag = document.createElement(tagname);
      tag.setAttribute(valuetype, value);
    }
    tag.setAttribute(valuetype, value);
    document.getElementsByTagName("head")[0].appendChild(tag);
  }
};
